@font-face {
    font-family: 'Arial';
    src:url("../fonts/Arial.ttf");
}

@font-face {
    font-family: 'Arial Bold';
    src:url("../fonts/Arial-bold.ttf");
}

// ITC Avant Garde Gothic Pro Bold
$title-font: itc-avant-garde-gothic-pro, sans-serif;
$title-bold: 700;
$title-medium: 500;
$title-light: 300;

$body-font: 'Arial';

$color-blue: #00B1B1;
$color-grey: #50687B;
$color-lightblue: #E8FFF5;
$color-orange: #f07d22;
$color-green: #42b284;
$color-purple: #6f1e82;
$color-darkblue: #125fab;
$color-lightpurple: #f1e9f3;

$font-primary: $title-font;

$color-primary: #661c4a;
$color-secondary: #978a37;
$color-white: #fff;
$color-black: #000;

h1, h2, h3, h4, h5, p, a, span, li {
	font-family: $body-font;
}

p {
	color: $color-grey;
	font-size: 14px;
	@media(min-width: 768px){
		font-size: 16px;
	}
	@media(min-width: 992px){
		font-size: 18px;
	}
	@media(min-width: 1200px){
		font-size: 20px;
	}
	@media(min-width: 1400px){
		font-size: 22px;
	}
	@media(min-width: 1600px){
		font-size: 26px;
	}
	@media(min-width: 1800px){
		font-size: 32px;
	}
}

:root {
	--font-small: 15px;

	@media(min-width:768px){
		--font-small:16px;
	}
	@media(min-width:992px){
		--font-small:17px;
	}
}

main {
	width: 100%;
	height: auto;
	position: relative;
	overflow: hidden;
}

strong {
	font-family: $title-font;
	font-weight: $title-light;
}

body.__locked {
	overflow: hidden;
}

.header {
	width: 100%;
	height: auto;
	position: relative;
	max-width: 1200px;
	padding: 1.5rem 0;
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: center;

	@media(min-width: 992px){
		padding: 2.5rem 0;
		height: 15vh;
	}
}

.header__logo {
	width: auto;
	height: 34px;
	display: block;

	@media(min-width: 992px){
		height: 55px;
	}
	@media(min-width: 1200px){
		height: 60px;
	}

	svg {
		width: 100%;
		height: 100%;
	}
}

.feature {
	width: 100%;
	height: auto;
	position: relative;
	margin-bottom: 1.5rem;
}

.feature__wrapper {
	width: 100%;
	height: auto;
	position: relative;
	padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.feature__media {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	video {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.feature__toggle {
	position: absolute;
	bottom: 0;
	right: 0;
	padding: 0.5rem 1rem;
	background-color: $color-blue;
	outline: none;
	border: none;
	display: flex;
	align-items: center;
	// -webkit-box-shadow: 3px 4px 12px -7px rgba(0,0,0,0.75);
	// -moz-box-shadow: 3px 4px 12px -7px rgba(0,0,0,0.75);
	// box-shadow: 3px 4px 12px -7px rgba(0,0,0,0.75);
	transition: all 0.3s ease;
	z-index: 2;
	opacity: 1;

	@media(min-width:768px){
		padding: 1rem 2rem;
	}

	&:hover,
	&:focus {
		background-color: darken($color-blue, 10%);
		color: #fff;
		text-decoration: none;
	}

	&.hide {
		// display: none;
	}
}

.feature__toggleText {
	color: #fff;
	font-family: $title-font;
	font-weight: 400;
	font-size: 12px;
	margin-right: 5px;
	position: relative;

	@media(min-width:768px){
		font-size: 14px;
	}
	@media(min-width:992px){
		font-size: 16px;
	}
}

.feature__svgPlay {
	display: flex;
	justify-content: center;
	width: 15px;
	height: 15px;

	@media(min-width:768px){
		width: 20px;
		height: 20px;
	}
	&.hide {
		display: none;
	}
	svg {
		fill: #fff;
		position: relative;
		width: 100%;
		height: 100%;
	}
}
.feature__svgMute {
	display: flex;
	justify-content: flex-start;
	padding-right: 6px;
	width: 15px;
	height: 15px;

	@media(min-width:768px){
		width: 20px;
		height: 20px;
		padding-right: 8px;
	}
	&.hide {
		display: none;
	}
	svg {
		fill: #fff;
		position: relative;
		width: 100%;
		height: 100%;
	}
}

.banner {
	width: 100%;
	height: 325px;
	position: relative;

	@media(min-width: 768px){
		height: 650px;
	}
	@media(min-width: 992px){
		height: 70vh;
		max-width: 90%;
		margin: 0 auto;
	}

	.videoplay {
		bottom: 15%;
		top: auto;
		left: 50%;
		transform: translate(-50%, -50%);
		@media(min-width: 768px){
			width: 110px;
			height: 110px;
			bottom: 10%;
			top: auto;
		}
		@media(min-width: 992px){
			width: 140px;
			height: 140px;
			bottom: 0;
		}
		@media(min-width: 1600px){
			width: 190px;
			height: 190px;
		}
		@media(min-width: 1800px){
			width: 215px;
			height: 215px;
		}

		svg {
			width: 100%;
			height: 100%;
		}
	}

	img {
		object-position: top;
	}
}

.banner__text {
	position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
	color: $color-white;
	font-family: $title-font;
	font-weight: $title-bold;
	z-index: 4;
	font-size: 38px;
	transition: all 0.3s ease;
	text-align: center;
    width: 100%;

	@media(min-width: 768px){
		font-size: 70px;
	}
	@media(min-width: 992px){
		font-size: 90px;
		top: 35%;
	}
	@media(min-width: 1200px){
		font-size: 95px;
	}
	@media(min-width: 1400px){
		font-size: 100px;
	}
	@media(min-width: 1600px){
		font-size: 115px;
	}
	@media(min-width: 1800px){
		font-size: 130px;
	}
}

.showreel-video {
	width: 100%;
	height: 100%;
    position: absolute;
    top: 0;
    left: 0;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #701e82;
		opacity: 1;
		mix-blend-mode: color;
		z-index: 2;
		transition: all 0.3s ease;
	}

	&:hover,
	&:focus {
		// svg {
		// 	fill: $color-secondary;
		// }
		// &::before {
		// 	opacity: 0.9;
		// }
	}
}


img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.videoplay {
	position: absolute;
	z-index: 3;
	top: 50%;
	left: 50%;
	width: 40px;
	height: 40px;
	transform: translate(-50%, -50%);
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.3s ease;

	@media(min-width: 375px){
		width: 55px;
		height: 55px;
	}

	@media(min-width: 768px){
		width: 40px;
		height: 40px;
	}

	@media(min-width: 992px){
		width: 55px;
		height: 55px;
	}

	svg {
		fill: $color-white;
		width: 100%;
		height: 100%;
		transition: all 0.3s ease;
	}

}


.double-video {
	width: 100%;
	position: relative;
	padding: 2rem 1.5rem;
	background-color: $color-lightblue;

	@media(min-width: 992px){
		padding: 5rem 1.5rem;
	}
}

.double-video__title {
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
	text-align: center;
	margin-bottom: 2rem;
	color: $color-grey;
	font-family: $title-font;
	font-weight: $title-medium;
	font-size: 17px;

	@media(min-width: 768px){
		font-size: 20px;
	}
	@media(min-width: 992px){
		font-size: 24px;
	}
	@media(min-width: 1200px){
		font-size: 30px;
	}
	@media(min-width: 1600px){
		font-size: 34px;
	}
	@media(min-width: 1800px){
		font-size: 34px;
	}
}

.double-video__row {
	width: 100%;
	display: block;
	max-width: 1200px;
	margin: 0 auto;

	@media(min-width: 768px){
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	}

	@media(min-width: 1400px){
		max-width: 1300px;
	}
}

.double-video__item {
	width: 100%;
	position: relative;

	@media(min-width: 768px){
		width: 45%;
	}

	h4 {
		text-align: center;
		color: $color-grey;
		font-family: 'Arial';
		font-weight: 400;
		font-size: 15px;

		@media(min-width: 768px){
			font-size: 22px;
		}
		@media(min-width: 992px){
			font-size: 24px;
		}
		@media(min-width: 1200px){
			font-size: 24px;
		}
		@media(min-width: 1600px){
			font-size: 26px;
		}
		@media(min-width: 1800px){
			font-size: 28px;
		}
	}
}

.double-video__media {
	margin-bottom: 1rem;
	display: block;
	position: relative;
	height: 250px;

	@media(min-width: 992px){
		height: 420px;
	}
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: $color-black;
		opacity: 0.3;
		transition: all 0.3s ease;
	}

	.videoplay {
		width: 110px;
		height: 110px;

		@media(min-width: 768px){
			width: 145px;
			height: 145px;
		}
		@media(min-width: 992px){
			width: 172px;
			height: 172px;
		}
	}

	&:hover,
	&:focus {
		&::before {
			opacity: 0.2;
		}
	}
}

.mfp-bg {
	opacity: 0.9!important;
	background: #000!important;
}
.mfp-close {
	color: $color-blue!important;
	font-size: 50px!important;
	height: 44px!important;
	opacity: 1!important;
	transition: all 0.3s ease!important;
	top: -40px!important;

	@media(min-width: 1600px){
		font-size: 75px!important;
	}

	&:hover,
	&:focus {
		color: darken($color-blue, 10%)!important;
	}
}

.mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 90%!important;
	position: relative;

	@media(min-width: 768px){
		max-width: 70%!important;
	}
	@media(min-width: 992px){

	}

	@media(min-width: 1600px){
		max-width: 90%!important;
		height: 75vh!important;
		#banner-video, #double-video, #double-video-alt {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			video {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			.mfp-close {
				top: -47px!important;
			}
		}
		.local-video-popup.small {
			width: 900px;
			height: 900px;
		}
	}


}

.mfp-wrap {
	width: 100%;
	height: 100%;
}

.local-video-popup {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	video {
		width: 100%;
		object-fit: contain;
	}

	&.small {

		@media(min-width: 768px){
			width: 70%;
			margin: 0 auto;
		}

		@media(min-width: 992px){
			width: 500px;
			height: 500px;
			margin: 0 auto;
		}
		video {
			height: 100%;
			width: 100%;
			object-fit: contain;
		}
	}
}

.imageblock {
	width: 100%;
	height: auto;
	position: relative;

	&.__green {
		.imageblock__media::before {
			background-color:$color-green;
		}
		.imageblock__media::after {
			opacity: 0.3;
		}
		.imageblock__aux {
			color:$color-green;
			p:first-of-type strong {
				color: $color-green;
			}
		}
	}

	&.__orange {
		.imageblock__media::before {
			background-color:$color-orange;
		}
		.imageblock__aux {
			color:$color-orange;
			p:first-of-type strong {
				color: $color-orange;
			}
		}
	}

	&.__purple {
		.imageblock__media::before {
			background-color:$color-purple;
		}
		.imageblock__aux {
			color:$color-purple;
			p:first-of-type strong {
				color: $color-purple;
			}
		}
		.imageblock__media::after {
			opacity: 0.2;
		}
	}

	&.__darkblue {
		.imageblock__media img {
			object-position: top;
		}
		.imageblock__media::before {
			background-color:$color-darkblue;
		}
		.imageblock__media::after {
			opacity: 0.3;
		}
		.imageblock__aux {
			color:$color-darkblue;
			p:first-of-type strong {
				color: $color-darkblue;
			}
		}
	}
}

.imageblock__media {
	width: 100%;
	height: 175px;
	position: relative;

	@media(min-width: 768px){
		height: 300px;
	}
	@media(min-width: 992px){
		height: 300px;
	}
	@media(min-width: 1700px){
		height: 400px;
	}

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: $color-blue;
		mix-blend-mode: color;
		z-index: 2;
	}

	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #000;
		z-index: 2;
		opacity: 0.1;
	}

	img {
		z-index: 1;
		position: relative;
	}
}

body {
	// scroll-behavior: smooth;
}

.imageblock__image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-size: cover;
    background-position: center;

	background-attachment: fixed;
}

.imageblock__overlay {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 3;
	color: #fff;
	font-size: 27px;
	font-family: $title-font;
	// font-weight: $title-bold;
	font-family: $title-medium;
	width: 100%;
	text-align: center;

	@media(min-width: 768px){
		font-size: 50px;
	}
	@media(min-width: 992px){
		font-size: 70px;
	}
	@media(min-width: 1200px){
		font-size: 80px;
	}
	@media(min-width: 1400px){
		font-size: 90px;
	}
	@media(min-width: 1600px){
		font-size: 95px;
	}
	@media(min-width: 1800px){
		font-size: 100px;
	}

	&::before {
		content: '';
		position: absolute;
		top: 120%;
		left: 50%;
		transform: translate(-50%, -50%);
		background-color: #fff;
		width: 100px;
		height: 3px;

		@media(min-width: 768px){
			top: 100%;
			width: 120px;
			height: 5px;
		}
	}
}

.imageblock__aux {
	padding: 2rem 1.5rem;
	font-size: 22px;
	margin: 0 auto;
	text-align: center;
	color: $color-blue;
	font-family: $title-font;
	font-weight: $title-light;

	@media(min-width: 992px){
		// padding: 4.5rem 1.5rem 7.5rem 1.5rem;
		padding: 4.5rem 1.5rem 4.5rem 1.5rem;
		line-height: 1.2;
	}

	strong {
		font-weight: $title-medium;
	}

	p {
		margin-top: 1.5rem;

		&:first-of-type {
			margin-top: 1.5rem;
			font-family: 'Arial';
			font-size: 18px;
			color: $color-grey;

			@media(min-width: 768px){
				font-size: 18px;
			}
			@media(min-width: 992px){
				font-size: 21px;
				margin-top: 3rem;
			}
			@media(min-width: 1200px){
				font-size: 24px;
			}
			@media(min-width: 1600px){
				font-size: 28px;
			}
			@media(min-width: 1800px){
				font-size: 38px;
			}

			strong {
				font-weight: $title-medium;
			}
		}

		&:nth-of-type(2) {
			font-family: 'Arial';
			font-size: 14px;
			color: $color-grey;

			@media(min-width: 768px){
				font-size: 15px;
			}
			@media(min-width: 992px){
				font-size: 16px;
			}
			@media(min-width: 1200px){
				font-size: 18px;
			}
			@media(min-width: 1600px){
				font-size: 20px;
			}
			@media(min-width: 1800px){
				font-size: 30px;
			}
		}
	}


	@media(min-width: 768px){
		font-size: 40px;
		max-width: 90%;
	}
	@media(min-width: 992px){
		font-size: 45px;
	}
	@media(min-width: 1200px){
		font-size: 50px;
	}
	@media(min-width: 1400px){
		font-size: 50px;
	}
	@media(min-width: 1600px){
		font-size: 60px;
	}
	@media(min-width: 1800px){
		font-size: 70px;
	}
}

.textblock {
	width: 100%;
	margin: 0 auto;
	max-width: 1200px;
	position: relative;
	padding: 2rem 1.5rem;
	text-align: center;

	@media(min-width: 768px){
		padding: 3.5rem 1.5rem;
	}

	@media(min-width: 1800px){
		max-width: 90%;
	}

	&.last {
		@media(min-width: 992px){
			padding: 5rem 1.5rem;
		}
	}

	h2 {
		color: $color-purple;
		font-family: $title-font;
		font-weight: $title-medium;
		font-size: 25px;
		margin-bottom: 1.5rem;

		@media(min-width: 768px){
			font-size: 33px;
		}
		@media(min-width: 992px){
			font-size: 50px;
			margin-bottom: 2.5rem;
		}
		@media(min-width: 1200px){
			font-size: 59px;
		}
		@media(min-width: 1600px){
			font-size: 64px;
		}
		@media(min-width: 1800px){
			font-size: 70px;
		}
	}

	h3 {
		font-family: 'Arial';
		font-size: 22px;
		color: $color-grey;

		@media(min-width: 992px){
			font-size: 32px;
		}
		@media(min-width: 1200px){
			font-size: 38px;
		}
		@media(min-width: 1600px){
			font-size: 44px;
		}
		@media(min-width: 1800px){
			font-size: 48px;
		}

		&.alt {
			color: $color-grey;
			strong {
				font-weight: 500;
			}
		}
	}

	h4 {
		font-family: 'Arial';
		font-size: 18px;
		color: $color-purple;
		margin-bottom: 1.5rem;

		@media(min-width: 768px){
			font-size: 22px;
		}
		@media(min-width: 992px){
			font-size: 28px;
		}
		@media(min-width: 1200px){
			font-size: 28px;
		}
		@media(min-width: 1600px){
			font-size: 32px;
		}
		@media(min-width: 1800px){
			font-size: 48px;
		}

		&.alt {
			color: $color-grey;


		}
	}

	ul {
		padding: 0;
		margin-bottom: 2rem;
		li {
			font-family: 'Arial';
			font-size: 18px;
			color: $color-grey;
			position: relative;
			margin-bottom: 15px;

			span {
				display: inline-block;
				position: relative;
				margin-right: 15px;
				margin-bottom: 2px;
				width: 8px;
				height: 8px;
				background-color: $color-grey;
				border-radius: 100%;
				vertical-align: middle;
			}

			&::marker {
				font-size: 0;
			}

			@media(min-width: 768px){
				font-size: 18px;
			}
			@media(min-width: 992px){
				font-size: 21px;
			}
			@media(min-width: 1200px){
				font-size: 24px;
			}
			@media(min-width: 1600px){
				font-size: 28px;
			}
			@media(min-width: 1800px){
				font-size: 38px;
			}
		}
	}

	.textblock__svg {
		margin: 3rem auto;
		max-width: 845px;
		display: block;

		@media(min-width: 992px){
			margin: 5rem auto;
		}

		&.first {
			margin: 0 auto 1.5rem auto;
		}
		&.alt {
			width: 250px;
			@media(min-width: 525px){
				width: 350px;
			}
			@media(min-width: 768px){
				width: 450px;
			}
			@media(min-width: 992px){
				width: 550px;
			}
			svg {
				width: 100%;
				height: 100%;
			}
		}
	}

	&.__purple {
		background-color: $color-lightpurple;

		&::before {
			content: '';
			position: absolute;
			width: 2000px;
			height: 100%;
			background-color: $color-lightpurple;
			top: 0;
			left: -2000px;
		}
		&::after {
			content: '';
			position: absolute;
			width: 2000px;
			height: 100%;
			background-color: $color-lightpurple;
			top: 0;
			right: -2000px;
		}
	}
}

.info {
	position: relative;
	width: 100%;
	padding: 1.5rem 1.5rem;
	max-width: 1200px;
	margin: 0 auto;

	@media(min-width: 768px){
		padding: 3.5rem 1.5rem;
	}

	&:first-of-type {
		padding: 0;
	}
}

.bioblock {
	position: relative;
	width: 100%;
	margin: 0 auto;
	max-width: 1100px;
	display: block;

	@media(min-width: 768px){
		display: flex;
		margin: 0 auto 1.5rem auto;
	}
	@media(min-width: 1300px){
		max-height: 508px;
	}
	@media(min-width: 1400px){
		max-width: 90%;
	}
	@media(min-width: 1600px){
		max-height: 450px;
	}
	@media(min-width: 1700px){
		max-height: 500px;
	}
	@media(min-width: 1800px){
		max-height: 550px;
	}
	@media(min-width: 2000px){
		max-height: 700px;
	}
	@media(min-width: 2500px){
		max-height: 800px;
	}
	@media(min-width: 2800px){
		max-height: 850px;
	}
	&.__purple {

		@media(min-width: 768px){
			flex-direction: row-reverse;
		}

		.bioblock-text {
			background-color: $color-lightpurple;

			div {
				p {
					color: $color-purple;
				}
			}
		}
	}
}

.bioblock-text {
	padding: 2rem;
	background-color: $color-lightblue;
	display: flex;
	flex-direction: column;
	justify-content: center;
	@media(min-width: 768px){
		width: 50%;
	}
	@media(min-width: 1200px){
		padding: 3.5rem;
	}

	p {
		font-family: 'Arial';
		display: block;

		@media(min-width: 992px){
			font-size: 19px;
		}
		@media(min-width: 1200px){
			font-size: 20px;
		}
		@media(min-width: 1400px){
			font-size: 22px;
		}
		@media(min-width: 1800px){
			font-size: 27px;
		}
	}

	div {
		display: flex;
		flex-direction: column;

		p {
			font-weight: $title-medium;
			color: $color-blue;

			&:first-of-type {
				margin-bottom: 0;
			}

			strong {
				font-family: 'Arial Bold';
			}
		}
	}
}

.bioblock-media {
	height: 350px;
	@media(min-width: 768px){
		width: 50%;
		height: auto;
	}

	img {
		object-position: top;
	}
}

.button {
	border: 1px solid $color-purple;
	border-top-left-radius: 15px;
	border-bottom-right-radius: 15px;
	font-family: $title-font;
	font-weight: $title-medium;
	color: $color-purple;
	padding: 0.5rem 3.5rem;
	font-size: 15px;
	margin: 0 auto;
	display: block;
	width: fit-content;
	transition: all 0.3s ease;
	text-decoration: none;

	@media(min-width:768px){
		font-size: 23px;
	}
	@media(min-width:992px){
		font-size: 25px;
	}
	@media(min-width:1200px){
		font-size: 27px;
	}
	@media(min-width:1600px){
		font-size: 29px;
	}
	@media(min-width:1800px){
		font-size: 31px;
	}

	&:hover,
	&:focus {
		background-color: $color-purple;
		color: $color-white;
		text-decoration: none;
	}
}

.arialbold {
	font-family: 'Arial bold'!important;
}